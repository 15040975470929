import { useMemo } from 'react';
import { Provider } from 'Types/types';
import { useClipboard } from 'use-clipboard-copy';

export type ProviderAuthOutput = {
    provider: Provider;
    authHeader: string;
    url: string;
    output?: { key: string; value: string }[];
};

interface ProviderAuthResponseProps {
    authData: ProviderAuthOutput;
}

export const ProviderAuthResponse = ({ authData }: ProviderAuthResponseProps): JSX.Element => {
    const urlClipboard = useClipboard({
        copiedTimeout: 2000,
    });
    const authClipboard = useClipboard({
        copiedTimeout: 2000,
    });

    const outputContainsOnlyHookAndUrl = useMemo(() => {
        return (
            authData.output?.length === 2 &&
            authData.output.some((o) => o.key === 'hook_authorization_header') &&
            authData.output.some((o) => o.key === 'hook_url')
        );
    }, [authData.output]);

    return (
        <div className="space-y-4">
            <p>
                <span className="text-yellow-500 font-semibold">Pending!</span> Your new integration was created.
            </p>
            {(authData.url || authData.authHeader) && (
                <div className="space-y-4">
                    <p>
                        To complete setting up this provider, it requires a WebHook to be configured in{' '}
                        <span className="capitalize">{authData.provider.type}</span>.
                    </p>
                    <p>To set the WebHook up, you will need these authentication credentials.</p>
                    <p>Please note them down as they will be shown only once.</p>
                </div>
            )}
            {authData.output && !outputContainsOnlyHookAndUrl && (
                <div className="space-y-4">
                    <p>To complete setting up this provider, you will need to use the following configuration data.</p>
                    <p>Please note this down as it will be shown only once.</p>
                </div>
            )}
            <div className="w-2/3 space-y-4 ml-4">
                {authData.url && (
                    <div>
                        <label htmlFor="email" className="block font-semibold">
                            Web Hook URL
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm h-8">
                            <div className="relative flex items-stretch flex-grow focus-within:z-10 ">
                                <input
                                    type="text"
                                    name="url"
                                    id="url"
                                    value={authData.url}
                                    readOnly={true}
                                    placeholder="https://okta.example.com/api/v1/webhooks/incoming/1234567890"
                                    className="input-gray rounded-none text-xs select-all"
                                />
                            </div>
                            <button
                                onClick={() => urlClipboard.copy(authData.url)}
                                className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                            >
                                <span>{urlClipboard.copied ? 'Copied!' : 'Copy'}</span>
                            </button>
                        </div>
                    </div>
                )}
                {authData.authHeader && (
                    <div>
                        <label htmlFor="email" className="block font-semibold">
                            Web Hook Authorization Code
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm h-8">
                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                <input
                                    type="text"
                                    name="authorization"
                                    id="authorization"
                                    value={authData.authHeader}
                                    readOnly={true}
                                    placeholder="Y2xpZW50X2lkOmNsaWVudF9zZWNyZXQ="
                                    className="input-gray rounded-none text-xs select-all"
                                />
                            </div>
                            <button
                                onClick={() => authClipboard.copy(authData.authHeader)}
                                className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                            >
                                <span>{authClipboard.copied ? 'Copied!' : 'Copy'}</span>
                            </button>
                        </div>
                    </div>
                )}
                {authData.output && !outputContainsOnlyHookAndUrl && (
                    <div className="space-y-4">
                        {authData.output
                            .toSorted((a, b) => {
                                if (a.key < b.key) return -1;
                                if (a.key > b.key) return 1;
                                return 0;
                            })
                            .map(({ key, value }) => (
                                <div>
                                    <label htmlFor="email" className="block font-semibold">
                                        {key}
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm h-8">
                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                            <input
                                                type="text"
                                                name={key}
                                                id={key}
                                                value={value}
                                                readOnly={true}
                                                className="input-gray rounded-none text-xs select-all"
                                            />
                                        </div>
                                        <button
                                            onClick={() => authClipboard.copy(value)}
                                            className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                                        >
                                            <span>{authClipboard.copied ? 'Copied!' : 'Copy'}</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
                <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(
                            authData,
                            (key, value) => {
                                if (key === '__typename') {
                                    return undefined;
                                }
                                return value;
                            },
                            '\t',
                        ),
                    )}`}
                    download="auth-credentials.json"
                    className="btn w-44"
                >
                    Download Credentials
                </a>
            </div>
        </div>
    );
};
